@import "styles/GlobalsVariables.scss";
.o-footer-container {
    .o-footer {
        background-color: $secondary-color;
        .container {
            .row {
                .col {
                    p {
                        a {
                            color: white;
                        }
                    }
                    .o-social-networks {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 30%;
                        a {
                            font-size: 2rem;
                        }
                    }
                    ul {
                        li {
                            span {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
        .o-click {
            cursor: pointer;
        }
        .footer-copyright {
            background-color: darken($secondary-color, 5%);
            padding: 20px 0.75rem;
        }
    }
}