@import "styles/GlobalsVariables.scss";
.o-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    z-index: 996;
    justify-content: center;
    align-items: center;
}