@import "styles/GlobalsVariables.scss";
.o-main-loading-container {
    position: initial;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    z-index: 999;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}