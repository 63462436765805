@import "styles/GlobalsVariables.scss";
.blogs {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 0.75rem 60px 0.75rem;

  h1 {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .blogs__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;

    .blogs__blog {
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #ababab;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      &:hover {
        border-color: $primary-color;
        box-shadow: 0 0 20px rgba(25, 0, 255, 0.2);
      }

      .blogs__blog__img {
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s ease;
        }
      }

      .blogs__blog__title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 10px;
        color: $black-color;
      }

      .blogs__blog__description {
        font-size: 1rem;
        margin-bottom: 20px;
        color: $black-color;
      }

      .blogs__blog__date {
        font-size: 0.9rem;
        font-weight: 500;
        color: #666;
      }
    }
  }
}

.blog {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  .img-container {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 700px;
      margin-bottom: 40px;
    }
  }

  .video-container {
    display: flex;
    justify-content: center;
  }

  ul {
    margin-bottom: 40px;
    li {
      font-size: 1rem;
      list-style: disc inside;
      margin-bottom: 10px;
    }
  }

  blockquote {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 20px;
  }

  span {
    display: block;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 20px;
  }

  a {
    text-decoration: underline;
  }
}

.button {
  width: fit-content;
  margin: auto;
  margin-bottom: 40px;
}
