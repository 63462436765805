@import "styles/GlobalsVariables.scss";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html,
body {
    font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
    height: 100%;
}


/* --------------------------- TEXTS  --------------------------- */

h1 {
    margin: 2rem 0px;
    &.o-small {
        font-size: 3.7rem;
    }
    &.o-big {
        font-size: 4.7rem;
    }
}

h2 {
    margin: 1.8rem 0px;
    &.o-small {
        font-size: 3rem;
    }
    &.o-big {
        font-size: 4rem;
    }
}

h3 {
    margin: 1.6rem 0px;
    &.o-small {
        font-size: 2.4rem;
    }
    &.o-big {
        font-size: 3.4rem;
    }
}

h4 {
    margin: 1.4rem 0px;
    &.o-small {
        font-size: 1.8rem;
    }
    &.o-big {
        font-size: 2.8rem;
    }
}

h5 {
    margin: 1.2rem 0px;
    &.o-big {
        font-size: 1.6rem;
    }
}

h6 {
    margin: 1rem 0px;
    &.o-big {
        font-size: 1.17rem;
    }
}

p {
    font-weight: 300;
    letter-spacing: 0.05rem;
}

span {
    font-size: 15px;
}

html {
    /* --------------------------- LETTER COLOR  --------------------------- */
    color: $black-color;
    .o-primary-color {
        color: $primary-color;
    }
    .o-secondary-color {
        color: $secondary-color;
    }
    .o-primary-dark-color {
        color: $primary-color-dark;
    }
    .o-black-color {
        color: $black-color;
    }
    .o-white-color {
        color: $white-color;
    }
    .o-red-color {
        color: $warning-toast-color;
    }
    .o-light-blue-color {
        color: $light-blue;
    }
    /* --------------------------- BACKGROUNDS COLOR  --------------------------- */
    .o-primary-color-fill {
        background-color: $primary-color;
    }
    .o-secondary-color-fill {
        background-color: $secondary-color;
    }
    .o-primary-dark-color-fill {
        background-color: $primary-color-dark;
    }
    .o-black-color-fill {
        background-color: $black-color;
    }
    .o-white-color-fill {
        background-color: $white-color;
    }
    .o-red-color-fill {
        background-color: $warning-toast-color;
        &:hover {
            background-color: darken($warning-toast-color, 10%);
        }
    }
}


/* --------------------------- MODIFIERS  --------------------------- */

.fw-500 {
    font-weight: 500;
}

.mt-18px {
    margin-top: 18px !important;
}

.mb-18px {
    margin-bottom: 18px !important;
}

.ml-18px {
    margin-left: 18px !important;
}

.mr-18px {
    margin-right: 18px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.fs-90em {
    font-size: 0.9em !important;
}

.no-min-height {
    min-height: none;
}

.pr-75rem {
    padding-right: 0.75rem;
}

.pl-75rem {
    padding-left: 0.75rem;
}

.o-highlight-text {
    color: lighten($primary-color, 10%);
}

.o-no-border {
    border: none;
}

.o-container {
    margin: 0 auto;
    max-width: 1920px;
    position: relative;
    width: 90%;
    @include media(mdDown) {
        width: 90%;
    }
    &.o-container-full-width {
        width: 100%;
    }
}

.o-full-height {
    height: 100vh;
    .row {
        margin-bottom: 0px;
    }
}

.o-pointer {
    cursor: pointer;
}


/* --------------------------- BUTTONS  --------------------------- */

.btn {
    box-shadow: none;
    &:hover {
        background-color: lighten($secondary-color, 7%);
        color: $white-color;
    }
    &:focus {
        background-color: $primary-color;
    }
}

.o-btn {
    background-color: $primary-color;
    border-radius: $border-radius-btn;
    font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
    font-weight: 500;
    height: auto;
    min-height: 50px;
    letter-spacing: 0.05em;
    margin-top: 20px;
    &.o-btn-square {
        border-radius: 0px;
    }
    &.o-btn-outlined {
        background-color: white;
        border: 1px solid $primary-color;
        color: $primary-color;
        &.o-red-color {
            border: 1px solid $warning-toast-color;
            color: $warning-toast-color;
            &:hover {
                background-color: $white-color;
            }
        }
        &.focus {
            background-color: white;
        }
        &:hover {
            background-color: lighten($primary-color, 45%);
        }
    }
    &.o-btn-full-width {
        width: 100%;
    }
    &.o-btn-cta {
        display: inline-block;
        font-size: 18px;
        font-weight: 300;
        height: 60px;
        line-height: 60px;
        padding-left: 25px;
        padding-right: 25px;
        text-transform: none;
        @include media(mdDown) {
            font-size: 15px;
        }
    }
}


/* --------------------------- INPUTS  --------------------------- */

input,
textarea {
    border-radius: $border-radius-input !important;
    border: 1px solid $borders-gray-color !important;
    box-sizing: border-box !important;
    font-weight: 300;
    margin-bottom: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
    // Focused input style
    &:focus:not([readonly]) {
        border: 1px solid $primary-color !important;
        box-shadow: none;
    }
}

textarea.materialize-textarea {
    min-height: 6rem;
}

.input-field {
    margin-top: 2.5rem;
    .character-counter {
        float: right;
        font-size: 12px;
        min-width: 10px;
    }
}

.input-field>label {
    color: $black-color;
    top: -5px;
    transform: translateY(12px) !important;
}

.input-field.col {
    margin-bottom: 0px !important;
}

.input-field.col label {
    font-size: 0.8em;
    font-weight: 500;
    top: -35px;
    span.required {
        background: #ba2726;
        border-radius: 50%;
        display: block;
        height: 5px;
        position: absolute;
        right: -10px;
        top: 5px;
        width: 5px;
    }
}

.react-tel-input {
    margin-top: 2.5rem;
    .special-label {
        display: block !important;
        color: $black-color;
        font-size: 0.8em !important;
        font-weight: 500;
        left: 0px !important;
        top: -24px !important;
        font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
        span.required {
            position: absolute;
            top: 5px;
            right: -10px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #ba2726;
            display: block;
        }
    }
    input {
        padding-left: 50px !important;
        &:focus~.flag-dropdown {
            border-top-color: $primary-color;
            border-left-color: $primary-color;
            border-bottom-color: $primary-color;
        }
    }
}


/* --------------------------- SWITCH  --------------------------- */

.switch {
    label {
        .lever::before {
            top: 1px !important;
        }
        .lever::after {
            top: 1px !important;
        }
        .lever {
            margin-right: 0px;
            background-color: $borders-gray-color !important;
        }
         :checked+.lever::after {
            background-color: $primary-color-dark !important;
        }
    }
}


/* --------------------------- TOAST  --------------------------- */

.toast {
    color: lighten($black-color, 15%) !important;
    font-size: 0.9em;
    width: 500px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $black-color !important;
    }
    p {
        margin-right: 30px;
    }
    .btn {
        align-items: center;
        background-color: transparent;
        color: $black-color;
        display: flex;
        font-size: 2em;
        justify-content: center;
        position: absolute;
        right: 15px;
        top: 5px;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
        border-radius: 0;
    }
}

.toast.success,
.o-toast.success {
    background-color: $success-toast-color;
}

.toast.error,
.o-toast.error {
    background-color: $error-toast-color;
}

.toast.info,
.o-toast.info {
    background-color: $info-toast-color;
}

.toast.warning,
.o-toast.warning {
    color: white !important;
    background-color: $warning-toast-color;
    h6 {
        color: white !important;
    }
}

.o-main {
    display: flex;
    @include media(mdDown) {
        flex-direction: column;
    }
}

.o-row-qr {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
    margin-top: 50px;
    .o-col-qr {
        margin-left: inherit !important;
        margin: 0 -30px;
    }
    &.o-png {
        margin-top: 40%;
    }
}

.o-container-help-btn {
    bottom: 15px;
    position: fixed;
    right: 15px;
    z-index: 999;
}