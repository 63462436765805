/* --------------------------- VARIABLES  --------------------------- */


/* Colors */

$primary-color: #3f51b5;
$secondary-color: #292f4c;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%);
$borders-gray-color: #e0e0e0;
$black-color: #323338;
$black-color-sendgrid: #121c2d;
$success-toast-color: #c8e6c9;
$error-toast-color: #fceeca;
$warning-toast-color: #e2445c;
$info-toast-color: #e1f5fe;
$success-invoice-color: #8ac249;
$disabled-link: #90a4ae;
$light-blue: #64b5f6;
$white-color: #fff;
$yellow-color: #e89a3a;

/* Borders */

$border-radius-input: 5px;
$border-radius-btn: 400px;

/* BREAKPOINTS  */

$breakpoint-xs: 360px;
$breakpoint-sm: 600px;
$breakpoint-md: 992px;
$breakpoint-mdDown: 992px;
$breakpoint-lg: 1225px;
@mixin media($media) {
    @if $media==xs {
        @media (max-width: $breakpoint-sm) {
            @content;
        }
    }
    @else if $media==sm {
        @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
            @content;
        }
    }
    @else if $media==md {
        @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
            @content;
        }
    }
    @else if $media==mdDown {
        @media (max-width: $breakpoint-mdDown) {
            @content;
        }
    }
    @else if $media==lg {
        @media (min-width: $breakpoint-lg) {
            @content;
        }
    }
}