@import "styles/GlobalsVariables.scss";
.o-static-toast-container {
    .o-toast-container {
        display: block;
        @media only screen and (min-width: 993) {
            max-width: 86%;
        }
        @media only screen and (max-width: 992) {
            max-width: 90%;
        }
        .o-toast {
            align-items: center;
            border-radius: 2px;
            color: #565860;
            cursor: default;
            display: flex;
            font-size: 0.9em;
            font-weight: 300;
            height: auto;
            justify-content: space-between;
            line-height: 1.5em;
            max-width: 100%;
            min-height: 48px;
            opacity: 1;
            padding-right: 50px;
            padding: 10px 25px;
            position: relative;
            top: 0px;
            .o-content {
                text-align: justify;
                display: flex;
                flex-wrap: wrap;
                margin-right: 15px;
                .o-action-container {
                    cursor: pointer;
                    margin-left: 5px;
                    text-decoration: underline;
                }
                p {
                    margin: 0px;
                    margin-top: 5px;
                    line-height: 1.8em;
                }
                a {
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
            h6 {
                color: $black-color;
                margin-right: 15px;
            }
            .btn {
                align-items: center;
                background-color: transparent;
                box-shadow: none;
                color: #323338;
                display: flex;
                font-size: 2em;
                justify-content: center;
                position: absolute;
                right: 0px;
                top: 5px;
            }
        }
    }
}