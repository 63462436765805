@import "styles/GlobalsVariables.scss";
.o-info-container {
    margin-left: 60px;
    .o-static-toast-container {
        .o-toast-container {
            margin: 0px;
            .o-toast {
                z-index: 999;
            }
            .o-toast.warning,
            .info,
            .error {
                margin-top: 0px;
                border-radius: 0px;
                .o-content {
                    margin-bottom: 0px;
                    margin-top: 0px;
                }
            }
        }
    }
    @include media(mdDown) {
        margin-left: 0px;
        .o-toast-container {
            .o-toast {
                .o-content {
                    .o-action-container {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}