@import "styles/GlobalsVariables.scss";
.o-blank {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: $white-color;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
}

.o-ads-container {
    margin-bottom: 100vh !important;
    .progress {
        //background-color: $borders-gray-color;
        background-color: #f6f7f9;
        border-radius: 0px;
        .determinate {
            background-color: #64b5f6;
            transition: none;
        }
    }
    .o-subtitle {
        font-size: 1.722rem;
        font-weight: 300;
        margin: 1.148rem 0 0.6888rem 0;
    }
    p,
    b,
    span {
        font-size: 0.9em;
        letter-spacing: normal;
        margin-bottom: 0px;
    }
    p {
        margin-top: 5px;
    }
    b {
        display: block;
        margin-top: 10px;
    }
    .progress {
        margin: 0px;
    }
    span {
        font-size: 14px;
    }
    .o-ad {
        margin-top: 20px;
    }
}