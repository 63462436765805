@import "styles/GlobalsVariables.scss";
.o-nav-container {
    nav {
        background-color: #fff;
        box-shadow: none;
        margin-bottom: 40px;
        padding-top: 20px;
        a {
            color: $primary-color;
            font-size: 1em !important;
            font-weight: 300;
            &.brand-logo {
                font-size: 1.5em !important;
                margin-top: -7px;
                padding-left: 20px;
            }
            &.btn {
                color: #fff;
                font-weight: 400;
                height: 45px;
                line-height: 45px;
                margin-left: 30px;
                padding-left: 20px;
                padding-right: 20px;
                text-transform: none;
            }
        }
        .o-btn {
            font-size: 1em !important;
            font-weight: 300;
            margin-right: 15px;
            text-transform: initial;
            &.btn {
                color: #fff;
                font-weight: 400;
                height: 45px;
                line-height: 45px;
                margin-left: 30px;
                margin-top: 0px;
                padding-left: 20px;
                padding-right: 20px;
                text-transform: none;
            }
        }
        .o-login-btn {
            @include media(mdDown) {
                padding: 0px 20px;
            }
        }
        .o-login-btn:hover {
            background-color: transparent;
        }
        .nav-wrapper {
            .brand-logo {
                cursor: pointer;
            }
            ul.right {
                display: block !important;
                .o-custom-qr-icon {
                    cursor: pointer;
                }
            }
        }
    }
}