.o-show-menu-container {
  position: relative;
  min-height: 100vh;
  padding: 8px 10px 0px 10px;
  background-color: #f1f0f7;
  .o-loading-container {
    top: 0;
    left: 0;
  }
  img {
    box-shadow: 0px 0px 4px 2px #c2c9d6;
    margin-bottom: 8px;
  }
  .o-empty-section-container {
    img {
      box-shadow: none;
      margin-bottom: 0px;
    }
  }
}

.o-footer-show-menu {
  width: 100%;
  background-color: #3f51b5;
  color: white;
  margin-bottom: 0;
  font-size: 12px;
  text-align: center;
  padding: 6px 0;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    color: white;
  }
}
