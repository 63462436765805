@import "styles/GlobalsVariables.scss";
.o-empty-section-container {
    width: 100%;
    //border: solid 1px $borders-gray-color;
    padding: 42px 6px 42px;
    margin: 0 auto;
    margin-bottom: 20px;
    .o-content {
        .o-img-container {
            margin: 0 auto 24px;
            max-width: 240px;
        }
        h3,
        p {
            margin: 0 auto;
            margin-top: 18px;
            max-width: 640px;
            text-align: center;
        }
    }
}